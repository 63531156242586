<!--
 * @Author: a1140836302 1140836302@qq.com
 * @Date: 2022-08-16 18:59:23
 * @LastEditors: a1140836302 1140836302@qq.com
 * @LastEditTime: 2022-08-24 21:27:25
 * @FilePath: \omes-ui\src\views\performance-analysis\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="page-content">
        <iframe class="embed-responsive-item" frameborder="0" ref="formurl" src="https://calc.pinphon.com/#/information/performance"></iframe>
    </div>
</template>

<script>

export default {
    mounted() {
        
    },
}
</script>

<style>
    .embed-responsive-item{
        width: 100%;
        height: 100%;
    }
</style>